/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* @import "@ionic/angular/css/palettes/dark.always.css"; */
/* @import "@ionic/angular/css/palettes/dark.class.css"; */
@import '@ionic/angular/css/palettes/dark.system.css';


@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


// Fungsi untuk menghasilkan rentang nilai
@function generate-sizes($min, $max, $step) {
  $map: ();
  @for $i from $min through $max {
    $value: $i * $step;
    // $key: unquote($value); // Menghilangkan tanda kutip pada kunci map
    $map: map-merge($map, ($i: $value));
  }
  @return $map;
}

// Definisikan skala spasi
$spacing-scale: generate-sizes(0, 12, 0.25rem);

// Fungsi untuk mengambil nilai dari skala
@function get-spacing($key) {
  @return map-get($spacing-scale, $key);
}

// Mixin untuk margin
@mixin margin($direction, $size) {
  margin-#{$direction}: get-spacing($size);
}

// Mixin untuk padding
@mixin padding($direction, $size) {
  padding-#{$direction}: get-spacing($size);
}

// Generate kelas secara otomatis
@each $key, $value in $spacing-scale {
  .m-#{$key}    { @include margin(all, $key); }
  .mt-#{$key}   { @include margin(top, $key); }
  .mr-#{$key}   { @include margin(right, $key); }
  .mb-#{$key}   { @include margin(bottom, $key); }
  .ml-#{$key}   { @include margin(left, $key); }
  .mx-#{$key}   { @include margin(left, $key); @include margin(right, $key); }
  .my-#{$key}   { @include margin(top, $key); @include margin(bottom, $key); }

  .p-#{$key}    { @include padding(all, $key); }
  .pt-#{$key}   { @include padding(top, $key); }
  .pr-#{$key}   { @include padding(right, $key); }
  .pb-#{$key}   { @include padding(bottom, $key); }
  .pl-#{$key}   { @include padding(left, $key); }
  .px-#{$key}   { @include padding(left, $key); @include padding(right, $key); }
  .py-#{$key}   { @include padding(top, $key); @include padding(bottom, $key); }
}

/* Color Variable */
:root {
  --primary-color: #379fb8;
  --primary-color-hover: #0a718a;
  --primary-color-light: #edfbff;
  --danger-color: #b84037;
  --danger-color-hover: #8a0a0a;
  --success-color: #37b87c;
  --success-color-hover: #0a8a55;
  --warning-color: #b8b437;
  --warning-color-hover: #8a810a;
}

/* Typography */
* {
  font-family: Poppins, Helvetica, "sans-serif";
}

.fs-10 { font-size: .5rem !important; }
.fs-9 { font-size: .75rem !important; }
.fs-8 { font-size: .85rem !important; }
.fs-7 { font-size: .95rem !important; }
.fs-6 { font-size: 1.075rem !important; }
.fs-5 { font-size: 1.15rem !important; }
.fs-4 { font-size: 1.25rem !important; }
.fs-3 { font-size: calc(1.26rem + .12vw) !important; }
.fs-2 { font-size: calc(1.275rem + .3vw) !important; }
.fs-1 { font-size: calc(1.3rem + .6vw) !important; }

.fw-bolder {
  font-weight: 600 !important;
}

.a-link {
 cursor: pointer;
}

.link-primary {
  color: var(--primary-color) !important;
}

.link-primary:hover {
  color: var(--primary-color-hover) !important;
}

.text-dark {
  color:#211f1c!important;
}

.text-gray-400 {
  color: #b5b0a1 !important;
}

.text-center {
  text-align: center;
}

a {
  text-decoration: none !important;
}

/* button */
ion-button.btn {
  --border-radius: .95rem;
  --padding-top: calc(.825rem + 1px) !important;
  --padding-bottom: calc(.825rem + 1px) !important;

  ion-spinner {
    margin-top: calc((-.825rem + 4px)) !important;
    margin-bottom: calc((-.825rem + 4px)) !important;
  }
}

ion-button.btn-primary {
  --background: var(--primary-color);
  --background-hover: var(--primary-color-hover);

  --box-shadow: 0 0px 0px 0 rgb(0, 0, 0, 0);
}

ion-button.btn-primary:hover {
  --background: var(--primary-color-hover);
}

ion-button.btn-danger {
  --background: var(--danger-color);
  --background-hover: var(--danger-color-hover);

  --box-shadow: 0 0px 0px 0 rgb(0, 0, 0, 0);
}

ion-button.btn-danger:hover {
  --background: var(--danger-color-hover);
}

/* Heading */
h1 {
  font-size: calc(1.3rem + .6vw);
}


@media (max-width: 1199px) {
  .on-xl, .min-xl {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .max-lg, .on-lg {
    display: none;
  }
}

@media (max-width: 991px) {
  .min-lg, .on-lg {
    display: none;
  }
}

@media (min-width: 992px) {
  .max-md, .on-md {
    display: none;
  }
}

@media (max-width: 767px) {
  .min-md, .on-md {
    display: none;
  }
}

@media (min-width: 768px) {
  .max-sm, .on-sm {
    display: none;
  }
}

@media (max-width: 575px) {
  .min-sm, .on-sm {
    display: none;
  }
}

@media (min-width: 576px) {
  .max-xs, .on-xs {
    display: none;
  }
}

/* Image */
ion-img.rounded::part(image) {
  border-radius: 10px !important;
}


/* Form */
ion-input.custom.ios .input-bottom .helper-text,
ion-input.custom.ios .input-bottom .counter,
ion-input.custom.md .input-bottom .helper-text,
ion-input.custom.md .input-bottom .counter {
  color: var(--ion-color-primary);
}

ion-item.form-group {
  overflow: visible !important;
  // ion-label {
  //   z-index: 1000 !important;
  // }
  // ion-label[ng-reflect-position="stacked"] {
  //   margin-bottom: .5rem !important;
  //   font-size: 1.05rem !important;
  // }

  ion-input.input-label-placement-floating {
    .label-text-wrapper {
      height: 19px !important;
    }
  }

  ion-input.input-label-placement-fixed {
    .label-text-wrapper, .native-wrapper {
      padding-top: 10px !important;
    }
  }

  ion-input {
    --color: #716d66 !important;
    --placeholder-color: #716d66 !important;
    --placeholder-opacity: 0.8 !important;

    --padding-bottom: 10px !important;
    --padding-end: 10px !important;
    --padding-start: 10px !important;
    --border-radius: 10px !important;
  }

  ion-input.form-control {
    border: 1px #f8f6f2 solid;
    border-radius: 10px !important;
  }

  ion-input.form-control-solid {
    --background: #f8f6f2 !important;
    --border: 0px !important;
  }

  .input-bottom.sc-ion-input-md {
    border-top: 0px !important;
  }

  ion-text.error-input {
    opacity: 0;
    display: inline-block;
    position: absolute;
    bottom: -4px;
    right: 10px;
    z-index: -1;
    background: #fdfcce;
    padding: 1px 17px;
    border-radius: 15px;

    transition-duration: .35s;
  }
  ion-text.error-input.show {
    opacity: 1;
    bottom: -6px;
    z-index: 2;
  }
}


/* Card Style */
ion-card {
  box-shadow: 0 .1rem 1rem .25rem rgba(0, 0, 0, .05) !important;
  border-radius: 0.95rem !important;
}


/* Menu Style */
ion-menu {

  ion-toolbar, ion-content {
    --background: var(--primary-color-light);
  }

  ion-img.logo-menu {
    height: 50px;
  }
}

ion-menu::part(background) {
  background: green !important;
}

ion-menu.menu-pane-visible {
  width: 200px !important;
}


ion-list.menu-list {
  background: rgba(0,0,0,0);
  
  ion-item.menu-item {
    cursor: pointer;
    border-radius: 10px;
    --background: rgba(10, 113, 138, 0);

    -o-transition-duration: .3s;
    --transition: .3s !important;
    margin-bottom: 10px;
  }
  
  ion-item.menu-item:hover, ion-item.menu-item.active {
    --background: rgba(10, 113, 138, 1);
  }
}